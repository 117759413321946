<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li v-for="(item,index) in items" :key="index" :class="item.isHeading ? 'iq-menu-title' :activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''">
      <font-awesome-icon v-if="item.isHeading" icon="fa-regular fa-minus" />
      <span v-if="item.isHeading" style="white-space: break-spaces">{{ $t(item.title) }}</span>
      <router-link :to="item.link" v-if="!item.isHeading"
                   :class="`iq-waves-effect ${activeLink(item) && (item.children && item.children.length > 0) ? 'active' : activeLink(item) ? 'active' : ''}`"
                   v-b-toggle="item.name">
        <font-awesome-icon :icon="item.icon" v-if="item.isIconClass" style="margin-right:5px !important" />
        <template v-else v-html="item.icon">
        </template>
        <span style="white-space: break-spaces">{{ $t(item.title) }}</span>
        <font-awesome-icon v-if="item.children && item.children.length > 0" icon="fa-regular fa-chevron-right" class="iq-arrow-right" />
        <small v-html="item.append" :class="item.appendClass" />
      </router-link>
      <List v-if="item.children && item.children.length > 0" :items="item.children" :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))" :idName="item.name" :accordianName="`sidebar-accordion-${item.className}`" :className="`iq-submenu ${item.className}`" />
    </li>
  </b-collapse>
</template>
<script>
  import List from './ListStyle'
  import { xray } from '../../../config/pluginInit'
  export default {
    name: 'List',
    props: {
      items: Array,
      className: { type: String, default: 'iq-menu' },
      open: { type: Boolean, default: false },
      idName: { type: String, default: 'sidebar' },
      accordianName: { type: String, default: 'sidebar' }
    },
    components: {
      List
    },
    mounted() {
    },
    methods: {
      activeLink(item) {
        return xray.getActiveLink(item, this.$route.name)
      }
    }
  }
</script>
