import axios from './index'
import constant from '../config/constant'

export default {
  getAllTranslations() {
    return axios.get(constant.translateUrl, { responseType: 'json' })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
