
function getYear() {
  return window.vm.$moment().format("YYYY");
}
function getToday() {
  return window.vm.$moment().format("DD.MM.YYYY");
}
function getNow() {
  return window.vm.$moment().format("HH:mm");
}

function getDate(date) {
  return window.vm.$moment(date).format("DD.MM.YYYY");
}

function getHour(date) {
  return window.vm.$moment(date).format("HH:mm");
}

function getDateWithHour(date) {
  return window.vm.$moment(date).format("DD.MM.YYYY HH:mm");
}

function openCustomerSummaryTab(customerId) {
  window.vm.$swal.close();
  return window.vm.$router.push(`/customer/${customerId}/details`);
}

export { getYear, getToday, getNow, getDate, getHour, getDateWithHour, openCustomerSummaryTab }
