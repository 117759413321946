import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translationService from '../src/services/translation'
import store from '../src/store/index'

Vue.use(VueI18n)

const i18nInstance = new VueI18n({
  locale: store.getters['Auth/currentLanguage'],
  fallbackLocale: 'en',
  messages: {}
});

translationService.getAllTranslations()
  .then((response) => {
    for (const lang in response) {
      if (response.hasOwnProperty(lang)) {
        i18nInstance.setLocaleMessage(lang, response[lang]);
      }
    }
  })
  .catch(error => {
    console.error(error);
  });

export default i18nInstance;
