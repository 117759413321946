<template>
  <ul v-if="user" class="navbar-list">
    <li>
      <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
        <b-avatar v-if="user.profilePictureUrl" class="mr-3"
                  :src="user.profilePictureUrl">
        </b-avatar>
        <b-avatar v-else variant="primary" class="mr-3"
                  :text="user.nameTwoLetter"></b-avatar>
        <div class="caption">
          <h6 class="mb-0 line-height">{{ user.name }}</h6>
        </div>
      </a>
      <div class="iq-sub-dropdown iq-dropdown">
        <div class="iq-card shadow-none m-0">
          <div class="iq-card-body p-0 ">
            <div class="bg-primary p-3">
              <h5 class="mb-0 text-white line-height">{{ user.name }}</h5>
            </div>
            <a class="iq-sub-card iq-bg-primary-hover" @click="navigateToStaffProfile" style="cursor:pointer !important;">
              <div class="media align-items-center">
                <div class="rounded iq-card-icon iq-bg-primary">
                  <font-awesome-icon icon="fa-regular fa-file-user" />
                </div>
                <div class="media-body ml-3">
                  <h6 class="mb-0 ">{{ $t("ClinicApp.Profile") }}</h6>
                </div>
              </div>
            </a>
            <div class="d-inline-block w-100 text-center p-3">
              <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">
                {{ $t("Logout") }} &nbsp;<font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
  import authService from '../../../services/auth'

  export default {
    name: 'UserBar',
    methods: {
      logout() {
        authService.logout()
      },
      navigateToStaffProfile() {
        this.$router.push('/staff/profile');
      },
    },
    computed: {
      user: function () {
        return this.$store.getters['Auth/currentStaff'];
      }
    },
  }
</script>
