
const state = {
  currentModalId: '',
  currentModalTitle: '',
  dynamicComponent: null,
  componentProps: null
}

const mutations = {
  saveSetCurrentModalId(state, currentModalId) {
    state.currentModalId = currentModalId
  },
  saveSetCurrentModalTitle(state, currentModalTitle) {
    state.currentModalTitle = currentModalTitle
  },
  saveSetDynamicComponent(state, dynamicComponent) {
    state.dynamicComponent = dynamicComponent
  },
  saveSetComponentProps(state, componentProps) {
    state.componentProps = componentProps
  }
}

const actions = {
  setCurrentModalId({ commit }, currentModalId) {
    commit('saveSetCurrentModalId', currentModalId)
  },
  setCurrentModalTitle({ commit }, currentModalTitle) {
    commit('saveSetCurrentModalTitle', currentModalTitle)
  },
  setDynamicComponent({ commit }, dynamicComponent) {
    commit('saveSetDynamicComponent', dynamicComponent)
  },
  setComponentProps({ commit }, componentProps) {
    commit('saveSetComponentProps', componentProps)
  }
}

const getters = {
  currentModalId: state => {
    return state.currentModalId
  },
  currentModalTitle: state => {
    return state.currentModalTitle
  },
  dynamicComponent: state => {
    return state.dynamicComponent
  },
  componentProps: state => {
    return state.componentProps
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
