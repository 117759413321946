import axios from './index'

export default {
  getColors() {
    return axios.get("/data/shared/color")
      .then(response => {
        const translatedResponse = response.data.map(color => {
          color.name = window.vm.$t(color.name);
          return color;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCurrencyList() {
    return axios.get("/currency/listJson")
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getParities() {
    return axios.get("/currency/listParities")
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getYesNoList() {
    return axios.get("/data/shared/yesNoList")
      .then((response) => {
        const translatedResponse = response.data.map(v => {
          v.name = window.vm.$t(v.name);
          return v;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTrueFalseList() {
    return axios.get("/data/shared/trueFalseList")
      .then(response => {
        const translatedResponse = response.data.map(v => {
          v.name = window.vm.$t(v.name);
          return v;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getLanguagesList() {
    return axios.get("/data/shared/customerLanguage")
      .then(response => {
        const translatedResponse = response.data.map(language => {
          language.name = window.vm.$t(language.name);
          return language;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCultureList() {
    return axios.get('/data/shared/culture')
      .then(response => {
        const translatedResponse = response.data.map(language => {
          language.name = window.vm.$t(language.name);
          return language;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTimeList() {
    return axios.get('/data/shared/time')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getReferenceSourceList() {
    return axios.get("/data/common/referenceSource")
      .then(response => {
        const translatedResponse = response.data.map(referenceSource => {
          referenceSource.name = window.vm.$t(referenceSource.name);
          return referenceSource;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointmentStatusList() {
    return axios.get('/appointment_Status/listJson')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointmentTypeList() {
    return axios.get('/appointment_Type/listJson')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getQuestionTypes: function () {
    return axios.get("/customerFormTemplate/options")
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getNotifications: function () {
    return axios.get("/common/getNotifications")
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getStaffBranchCalendar: function (staffId) {
    return axios.get("/data/getStaffBranchCalendar?staffId=" + staffId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
