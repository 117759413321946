<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <auto-complete data-url="/Customer/AutoComplete"
                       data-hidden-name="LayoutCustomerID"></auto-complete>
        <b-navbar-toggle target="nav-collapse">
          <font-awesome-icon icon="fa-regular fa-bars" size="lg" />
        </b-navbar-toggle>
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><font-awesome-icon icon="fa-regular fa-ellipsis" size="lg" /></div>
            <div class="hover-circle"><font-awesome-icon icon="fa-regular fa-ellipsis-vertical" size="lg" /></div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse>
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
  import AutoComplete from '../../shared/AutoComplete'

  export default {
    name: 'NavBarStyle',
    components: {
      AutoComplete
    },
    methods: {
      miniSidebar() {
        this.$emit('toggle')
      }
    },
    mounted() {
      document.addEventListener('click', this.closeSearch, true)
    }
  }
</script>
