
const state = {
  horizontalMenu: false,
  miniSidebarMenu: false,
  lang: { title: 'English', value: 'en', image: require('../assets/images/small/flag-01.png') },
  langOption: [
    { title: 'English', value: 'en', image: require('../assets/images/small/flag-01.png') },
  ],
  colors: [
    { primary: '#827af3', primaryLight: '#b47af3', bodyBgLight: '#efeefd', bodyBgDark: '#1d203f' },
    { primary: '#e07af3', primaryLight: '#f37ab7', bodyBgLight: '#f7eefd', bodyBgDark: '#1d203f' },
    { primary: '#6475c7', primaryLight: '#7abbf3', bodyBgLight: '#eaf5ff', bodyBgDark: '#1d203f' },
    { primary: '#c76464', primaryLight: '#f3c37a', bodyBgLight: '#fff8ea', bodyBgDark: '#1d203f' },
    { primary: '#c764ad', primaryLight: '#de8ba9', bodyBgLight: '#ffeaf5', bodyBgDark: '#1d203f' },
    { primary: '#64c7ac', primaryLight: '#a3f37a', bodyBgLight: '#f0ffea', bodyBgDark: '#1d203f' },
    { primary: '#8ac764', primaryLight: '#dbf37a', bodyBgLight: '#f7ffea', bodyBgDark: '#1d203f' }
  ]
}

const mutations = {
  horizontalMenuCommit(state, data) {
    state.horizontalMenu = data
  },
  miniSidebarCommit(state, data) {
    state.miniSidebarMenu = data
  },
  authUserCommit(state, data) {
    state.authUser = data
  },
  setLangCommit(state, data) {
    state.lang = data
  }
}

const actions = {
  horizontalMenuAction(context) {
    if (context.state.horizontalMenu) {
      context.commit('horizontalMenuCommit', false)
    } else {
      context.commit('horizontalMenuCommit', true)
    }
  },
  miniSidebarAction(context) {
    return new Promise((resolve) => {
      if (context.state.miniSidebarMenu) {
        context.commit('miniSidebarCommit', false)
        resolve(false)
      } else {
        context.commit('miniSidebarCommit', true)
        resolve(true)
      }
    })
  },
  setLangAction(context, payload) {
    context.commit('setLangCommit', payload)
  }
}

const getters = {
  horizontalMenuState: state => state.horizontalMenu,
  miniSidebarState: state => state.miniSidebarMenu,
  langState: state => state.lang,
  langOptionState: state => state.langOption,
  colorState: state => state.colors,
  isMobileBrowser: state => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      state.isMobileBrowser = true;
      return true;
    } else {

      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); //eslint-disable-line
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; //eslint-disable-line

      if (isSafari && iOS) {
        state.isMobileBrowser = true;
        return true;
      } else {
        state.isMobileBrowser = false;
        return false;
      }



    }
  },
  isOrientationPortrait: state => {
    return state.deviceOrientation == "Portrait";
  },
  isOrientationLandscape: state => {
    return state.deviceOrientation == "Landscape";
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
