import {
  HubConnectionBuilder,
  LogLevel
} from "@microsoft/signalr";



const mutations = {
  saveSimplepeer(state, instance) {
    state.simplepeer = instance;
  },
  saveMeetingStream(state, stream) {
    state.meetingStream = stream;
  },
  setIsMeetingStarted(state, isMeetingStarted) {
    state.isMeetingStarted = isMeetingStarted;
  },
  saveMeethub(state, instance) {
    state.meethub = instance;
  },
  saveMeetingRecorder(state, recorder) {
    state.meetingRecorder = recorder;
  },
}

const actions = {
  initializeMeetingRecorder({
    commit
  }, recorder) {
    commit("saveMeetingRecorder", recorder);
  },
  initializeSimplepeer({
    commit,
    getters,
    state
  }, isHost) {

    const simplepeer = new window.SimplePeer({
      initiator: isHost == true,
      trickle: false,
      reconnectTimer: 500,
      iceTransportPolicy: "all",
      stream: getters.meetingStream,
      offerConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      },
      config: {
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      }
    });

    simplepeer.on('error', err => {
      state.simplepeerConnected = false;
      console.log('error', err)
    });

    simplepeer.on('connect', function () {
      state.simplepeerConnected = true;
    });

    simplepeer.on('signal', function (data) {
      if (data.renegotiate || data.transceiverRequest) return
      setTimeout(function () {
        state.meethub.invoke("SendSignal", JSON.stringify(data));
      }, 500);
    });

    simplepeer.on('disconnected', function () {
      state.simplepeerConnected = false;
    });

    simplepeer.on('close', function () {
      state.simplepeerConnected = false;
    });

    simplepeer.on('stream', function (stream) {
      const remotePlayer = document.getElementById('remotePlayer').getElementsByTagName("video")[0];
      if (remotePlayer) {
        const remoteStream = new MediaStream(stream);
        remotePlayer.srcObject = remoteStream;
        remotePlayer.play();
      }

      if (document.getElementById('localPlayer')) {
        const localPlayer = document.getElementById('localPlayer').getElementsByTagName("video")[0];

        if (localPlayer) {
          localPlayer.srcObject = new MediaStream(getters.meetingStream);

          localPlayer.play();
        }
      }
    });
    commit("saveSimplepeer", simplepeer);
  },
  setMeetingStream({
    commit
  }, stream) {
    commit("saveMeetingStream", stream);
  },
  setIsMeetingStarted({
    commit
  }, isMeetingStarted) {
    commit("setIsMeetingStarted", isMeetingStarted)
  },
  initializeMeethub({
    commit
  }, url) {
    const connection = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect([0, 3000, 5000, 7000, 10000, 15000])
      .build();

    commit("saveMeethub", connection);
  }
}
const getters = {
  meetingStream: state => {
    return state.meetingStream;
  },
  simplepeerConnected: state => {
    return state.simplepeerConnected;
  },
  isMeetingStarted: state => {
    return state.isMeetingStarted;
  },
  meethub: state => {
    return state.meethub;
  }
}

export default {
  namespaced: true,
  state: () => {
    return {
      simplepeerConnected: false,
      meetingStream: null,
      simplepeer: null,
      isMeetingStarted: false,
      meethub: null,
      meetingRecorder: null
    };
  },
  getters,
  actions,
  mutations
}
