<template>
  <div>
    <div id="show-overlay"></div>
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle :logo-full="logoFull" :logo-icon="logoIcon" :mini="mini" @toggle="sidebarMini" />
      <div id="content-page" class="content-page overflow-auto">
        <!-- TOP Nav Bar -->
        <NavBarStyle @toggle="sidebarMini">
          <template slot="responsiveRight">
            <ul class="navbar-nav ml-auto navbar-list">
              <li class="nav-item iq-full-screen">
                <a href="#" class="iq-waves-effect" id="btnFullscreen" @click="fullscreen"><font-awesome-icon :icon="fullscreenIcon" size="lg" /></a>
              </li>
             <notifications></notifications>
            </ul>
          </template>
          <template slot="right">
            <UserBar />
          </template>
        </NavBarStyle>
        <!-- TOP Nav Bar END -->
        <modal v-if="$router.currentRoute.meta.isModal == true" :key="$router.currentRoute.name"></modal>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view v-if="$router.currentRoute.meta.name == 'CustomerSummary'"
                       :key="$router.currentRoute.meta.name + '|' + $router.currentRoute.params.customerId"></router-view>
          <router-view v-else :key="$router.currentRoute.meta.name"></router-view>
        </transition>
        <FooterStyle>
          <template v-slot:left>
          </template>
          <template v-slot:right>
            <span class="text-dark">Ⓒ 2024 Softage Yazılım A.Ş.</span>&nbsp;
            <!--<span class="text-dark">v{{ $store.getters['Auth/clientVersion'] }}</span>-->
          </template>
        </FooterStyle>
      </div>
    </div>
  </div>
</template>
<script>
  import SideBarStyle from '../components/xray/sidebars/SideBarStyle'
  import NavBarStyle from '../components/xray/navbars/NavBarStyle'
  import FooterStyle from '../components/xray/footer/FooterStyle'
  import UserBar from '../components/xray/navbars/UserBar'
  import Modal from '../views/Pages/Shared/Modal'
  import profile from '../assets/images/user/1.jpg'
  import logoFull from '../assets/images/logo-full-lg.png'
  import logoIcon from '../assets/images/logo-icon.png'
  import { xray } from '../config/pluginInit'
  import authService from '../services/auth'
  import Notifications from '../components/xray/Notifications'

  export default {
    name: 'Layout',
    components: {
      SideBarStyle,
      NavBarStyle,
      FooterStyle,
      UserBar,
      Modal,
      Notifications
    },
    data() {
      return {
        mini: false,
        darkMode: false,
        fullscreenIcon: 'fa-regular fa-expand',
        animated: { enter: 'zoomIn', exit: 'zoomOut' },
        animateClass: [
          { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
          { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
          { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
          { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
        ],
        userProfile: profile,
        logoFull: logoFull,
        logoIcon: logoIcon,
        notification: [
          { image: require('../assets/images/user/01.jpg'), name: 'Nik Emma Watson', date: '23 hour ago', description: 'Enjoy smart access to videos, games' },
          { image: require('../assets/images/user/02.jpg'), name: 'Greta Life', date: '14 hour ago', description: 'Google Chromecast: Enjoy a world of entertainment' },
          { image: require('../assets/images/user/03.jpg'), name: 'Barb Ackue', date: '16 hour ago', description: 'Dell Inspiron Laptop: Get speed and performance from' },
          { image: require('../assets/images/user/04.jpg'), name: 'Anna Sthesia', date: '21 hour ago', description: 'Deliver your favorite playlist anywhere in your home ' },
          { image: require('../assets/images/user/05.jpg'), name: 'Bob Frapples', date: '11 hour ago', description: 'MacBook Air features up to 8GB of memory, a fifth-generation' }
        ]
      }
    },
    methods: {
      updateRadio() {
        this.mini = this.$store.getters['Setting/miniSidebarState']
      },
      sidebarMini() {
        xray.triggerSet()
        this.$store.dispatch('Setting/miniSidebarAction')
        this.updateRadio()
      },
      logout() {
        authService.logout()
      },
      fullscreen() {
        const elem = document.querySelector('.iq-full-screen');
        if (!document.fullscreenElement &&
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement &&
          !document.msFullscreenElement) {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          }
          this.fullscreenIcon = 'fa-regular fa-compress';
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
          this.fullscreenIcon = 'fa-regular fa-expand';
        }
        elem.querySelector('font-awesome-icon').setAttribute('icon', this.fullscreenIcon);
      }
    },
    mounted() {
      this.updateRadio();
    }
  }
</script>
<style>
  @import url("../assets/css/custom.css");
</style>
