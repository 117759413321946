import Vue from 'vue'
import moment from 'moment'
Vue.prototype.$moment = moment

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})

Vue.filter('dateFormat', function (value) {
  if (!value) return ''
  return moment(String(value)).format('DD.MM.YYYY')
})

Vue.filter("dateFormatWithHourForContainingT", function (value) {
  if (value && value.indexOf("T") != -1) {
    var splittedDateArray = value.split("T")[0].split("-");

    var splittedTimeArray = "";

    if (value.split("T")[1].indexOf("+") != -1)
      splittedTimeArray = value.split("T")[1].split("+");
    else if (value.split("T")[1].indexOf("-") != -1)
      splittedTimeArray = value.split("T")[1].split("-");
    else
      splittedTimeArray = value.split("T")[1];

    if (splittedDateArray.length > 0 && splittedTimeArray.length > 0) {
      return splittedDateArray[2] + "." + splittedDateArray[1] + "." + splittedDateArray[0] + ' ' + splittedTimeArray[0].slice(0, -3);
    }
    else
      return value;
  }
  else {
    return value;
  }
})
