<template>
  <div>
    <div class="wrapper">
      <router-view :key="$router.currentRoute.meta.name"></router-view>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BlankLayout',
  }
</script>
<style>
  @import url("../assets/css/custom.css");
</style>
