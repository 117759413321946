import axios from './index'

export default {
  getSideBarMenuItems() {
    return axios.get('/api/VegaCommon/GetSideBarMenu')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAutoCompleteItems(url, params) {
    return axios.get(url, params)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  autoCompleteSetSelected(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getLeftMenuItems(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
