import axios from 'axios'
import constant from '../config/constant'
import store from '../store/index'

let token = store.getters['Auth/token']
let currentBranchId = store.getters['Auth/currentBranchId']


axios.defaults.headers.common['Authorization'] = token
axios.defaults.headers.common['BranchID'] = currentBranchId

export default axios.create({
  baseURL: constant.webBaseURL,
  headers: {
    'Content-Type': 'application/json',
    //'Authorization': token,
    //'BranchID': currentBranchId
  }
})
