
const state = {
  calendarColorSource: localStorage == null ? "" : localStorage.getItem("calendarColorSource") || "appointmentStatus",
  generalCalendarType: localStorage == null ? "" : localStorage.getItem("generalCalendarType") || "dayGridMonth",
  lastSelectedCalendar: localStorage == null ? "" : localStorage.getItem("lastSelectedCalendar") || "appointmentCalendarGeneral",
  calendarStartTime: localStorage == null ? "" : localStorage.getItem("calendarStartTime") || "06:00",
  calendarEndTime: localStorage == null ? "" : localStorage.getItem("calendarEndTime") || "22:00"
}

const mutations = {
  saveSetCalendarColorSource(state, colorSource) {
    state.calendarColorSource = colorSource;
    localStorage.setItem("calendarColorSource", colorSource)
  },
  saveSetGeneralCalendarType(state, calendarType) {
    state.generalCalendarType = calendarType;
    localStorage.setItem("generalCalendarType", calendarType)
  },
  saveSetLastSelectedCalendar(state, calendarType) {
    state.lastSelectedCalendar = calendarType;
    localStorage.setItem("lastSelectedCalendar", calendarType)
  },
  saveCalendarStartTime(state, startTime) {
    state.calendarStartTime = startTime;
    localStorage.setItem("calendarStartTime", startTime)
  },
  saveCalendarEndTime(state, endTime) {
    state.calendarEndTime = endTime;
    localStorage.setItem("calendarEndTime", endTime)
  }
}

const actions = {
  setCalendarColorSource({ commit }, colorSource) {
    commit("saveSetCalendarColorSource", colorSource)
  },
  setGeneralCalendarType({ commit }, calendarType) {
    commit("saveSetGeneralCalendarType", calendarType)
  },
  setLastSelectedCalendar({ commit }, calendarType) {
    commit("saveSetLastSelectedCalendar", calendarType)
  },
  setCalendarStartTime({ commit }, startTime) {
    commit("saveCalendarStartTime", startTime);
  },
  setCalendarEndTime({ commit }, endTime) {
    commit("saveCalendarEndTime", endTime);
  }
}

const getters = {
  calendarColorSource: state => {
    return state.calendarColorSource;
  },
  generalCalendarType: state => {
    return state.generalCalendarType;
  },
  lastSelectedCalendar: state => {
    return state.lastSelectedCalendar;
  },
  calendarStartTime: state => {
    return state.calendarStartTime;
  },
  calendarEndTime: state => {
    return state.calendarEndTime;
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
