<template>
  <b-modal :id="currentModalId" :size="size" hide-footer>
    <template #modal-header>
      <h5>{{ $t(currentModalTitle) }}</h5>
      <font-awesome-icon class="close" icon="fa-regular fa-xmark" @click="close()" />
    </template>
    <template #default>
      <component v-if="dynamicComponent != null" v-bind:is="dynamicComponent" v-bind="componentProps" @closeModal="close" />
    </template>
  </b-modal>
</template>
<script>
  export default {
    name: 'Modal',
    data() {
      return {
        size: ''
      }
    },
    methods: {
      close() {
        this.$bvModal.hide(this.currentModalId)
      }
    },
    computed: {
      currentModalId: function () {
        return this.$store.getters['Modal/currentModalId']
      },
      currentModalTitle: function () {
        return this.$store.getters['Modal/currentModalTitle']
      },
      dynamicComponent: function () {
        return this.$store.getters['Modal/dynamicComponent']
      },
      componentProps: function () {
        return this.$store.getters['Modal/componentProps']
      }
    },
    beforeDestroy() {
      this.$root.$off('bv::modal::hide')
    },
    created() {
      let $this = this;
      $this.$root.$on('bv::modal::hide', () => {
        $this.$router.go(-1)
        $this.$store.dispatch('Modal/setCurrentModalId', '')
        $this.$store.dispatch('Modal/setCurrentModalTitle', '')
        $this.$store.dispatch('Modal/setDynamicComponent', null)
        $this.$store.dispatch('Modal/setComponentProps', null)
      })
    },
    mounted() {
      this.size = this.$router.currentRoute.meta.size ?? ''
      this.$bvModal.show(this.currentModalId)
    }
  }
</script>

<style>
  .modal-body {
    padding-bottom: 0px !important;
  }
</style>
