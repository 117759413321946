import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import i18n from './i18n'
import { interceptorsSetup } from '../src/helpers/axiosInterceptors'
import { openCustomerSummaryTab } from '../src/utils/dateTimeExtensions'
import authService from '../src/services/auth'

let VueEventBus = require("vue-event-bus");
Vue.use(VueEventBus);

import SimplePeer from "simple-peer";
window.SimplePeer = SimplePeer;

global.Raphael = Raphael
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (store.getters['Auth/isLoggedIn'] || to.name == "auth.sign-in-sso" || to.name == "joinToMeeting") {
    next()
  } else if (to.path === '/auth/sign-in') {
    next()
  } else {
    next('/auth/sign-in')
  }
})

let vm = new Vue({
  router,
  store,
  i18n,
  data: {
    axiosInterceptor: null
  },

  created() {
    const $this = this;

    $this.enableAxiosResponseInterceptor()
    $this.$store.dispatch('Auth/setCurrentUser')
    $this.$store.dispatch('Auth/setCurrentBranch', { branchId: null, branchName: null, timezone: null })

    $this.getTenantInfo()

    $this.$bus.$on('UiLanguageChanged', (language) => {
      $this.$i18n.locale = language;
      $this.$store.dispatch('Auth/setCurrentLanguage', language)
    });
  },
  methods: {
    enableAxiosResponseInterceptor() {
      interceptorsSetup()
    },
    getTenantInfo() {
      authService.getTenantInfo()
    },
    filterOptions(option, label, search) {
      // v-select componentinde filtrelemede kullan�l�yor
      return label.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode >= 48 && charCode <= 57) || charCode == 44 || charCode == 46 || charCode == 37) {
        return true;
      } else {
        evt.preventDefault();
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
window.vm = vm;
window.openCustomerSummaryTab = openCustomerSummaryTab;
