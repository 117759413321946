import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './settings'
import Auth from './auth'
import Modal from './modal'
import Calendar from './calendar'
import Meeting from './meeting'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    Setting,
    Auth,
    Modal,
    Calendar,
    Meeting
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
})
