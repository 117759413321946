
const state = {
  token: localStorage == null ? '' : localStorage.getItem('token') || '',
  isTokenRefreshing: false,
  user: {},
  tenantId: localStorage == null ? null : localStorage.getItem('tenantId') || null,
  currentBranchId: localStorage == null ? null : localStorage.getItem('currentBranchId') || null,
  currentBranchName: localStorage == null ? null : localStorage.getItem('currentBranchName') || null,
  currentBranchTimezone: localStorage == null ? null : localStorage.getItem('currentBranchTimezone') || null,
  currentLanguage: "tr",
  tenantInfo: {},
  clientVersion: localStorage == null ? "" : localStorage.getItem("clientVersion"),
  isClientUsingOldVersion: false,
  branchIsSaglikNetActive: localStorage == null ? false : localStorage.getItem("branchIsSaglikNetActive") === 'true' || false,
}

const mutations = {
  saveSetToken(state, token) {
    token = 'Bearer ' + token;
    localStorage.setItem('token', token)
    state.token = token
  },
  saveSetIsTokenRefreshing(state, isTokenRefreshing) {
    state.isTokenRefreshing = isTokenRefreshing
  },
  saveSetCurrentUser(state, user) {
    localStorage.setItem('user', JSON.stringify(user))
    state.user = user
  },
  saveSetTenantId(state, tenantId) {
    localStorage.setItem('tenantId', tenantId)
    state.tenantId = tenantId;
  },
  saveSetCurrentBranchId(state, branchId) {
    localStorage.setItem('currentBranchId', branchId)
    state.currentBranchId = branchId
  },
  saveSetCurrentBranchName(state, branchName) {
    localStorage.setItem('currentBranchName', branchName)
    state.currentBranchName = branchName
  },
  saveSetCurrentBranchTimezone(state, tz) {
    localStorage.setItem('currentBranchTimezone', tz)
    state.currentBranchTimezone = tz
  },
  saveSetCurrentLanguage(state, language) {
    state.currentLanguage = language;
  },
  saveSetTenantInfo(state, tenantInfo) {
    state.tenantInfo = tenantInfo;
  },
  saveSetClientVersion(state, version) {
    localStorage.setItem('clientVersion', version)
    state.clientVersion = version
  },
  saveSetIsClientUsingOldVersion(state, oldVersion) {
    state.isClientUsingOldVersion = oldVersion
  },
  saveSetBranchIsSaglikNetActive(state, isActive) {
    localStorage.setItem("branchIsSaglikNetActive", isActive)
    state.branchIsSaglikNetActive = isActive;
  },
}

const actions = {
  setToken({ commit }, token) {
    commit('saveSetToken', token)
  },
  setIsTokenRefreshing({ commit }, isTokenRefreshing) {
    commit('saveSetIsTokenRefreshing', isTokenRefreshing)
  },
  setCurrentUser({ commit }, user) {
    if (user == null)
      user = JSON.parse(localStorage.getItem('user'))

    if (user) {
      //dispatch('fetchAndsetMainMenuItems');
      commit('saveSetCurrentUser', user)
    }
  },
  setTenantId({ commit }, tenantId) {
    commit('saveSetTenantId', tenantId);
  },
  setCurrentBranch({ commit }, { branchId, branchName, timezone }) {

    if (!branchId) {
      branchId = localStorage.getItem('currentBranchId')
    }

    if (!branchName) {
      branchName = localStorage.getItem('currentBranchName')
    }

    if (!timezone) {
      timezone = localStorage.getItem('currentBranchTimezone')
    }

    return new Promise((resolve) => {
      localStorage.setItem('currentBranchId', branchId)
      commit('saveSetCurrentBranchId', branchId)

      localStorage.setItem('currentBranchName', branchName)
      commit('saveSetCurrentBranchName', branchName)

      localStorage.setItem('currentBranchTimezone', timezone)
      commit('saveSetCurrentBranchTimezone', timezone)

      resolve()
    })
  },
  setCurrentLanguage({ commit }, language) {
    commit("saveSetCurrentLanguage", language)
  },
  setTenantInfo({ commit }, tenantInfo) {
    commit("saveSetTenantInfo", tenantInfo);
  },
  setClientVersion({ commit }, version) {
    commit('saveSetClientVersion', version)
  },
  setIsClientUsingOldVersion({ commit }, version) {
    commit('saveSetIsClientUsingOldVersion', version)
  },
  setBranchIsSaglikNetActive({ commit }, isActive) {
    commit("saveSetBranchIsSaglikNetActive", isActive);
  },
}
const getters = {
  token: state => {
    return state.token
  },
  isTokenRefreshing: state => {
    return state.isTokenRefreshing
  },
  isLoggedIn: state => {
    if (state.token && state.token.length > 0)
      return true;
    else
      return false;
  },
  currentStaff: state => {
    return state.user
  },
  checkPermission: (state) => (permissionStr) => {
    if (state.user && state.user.authorizedPermissionNamesList && state.user.authorizedPermissionNamesList.indexOf(permissionStr) !== -1) {
      return true;
    } else {
      return false;
    }
  },
  tenantId: state => {
    return state.tenantId;
  },
  currentBranchId: state => {
    return state.currentBranchId
  },
  currentBranchName: state => {
    return state.currentBranchName
  },
  currentBranchTimezone: state => {
    return state.currentBranchTimezone
  },
  currentLanguage: state => {
    return state.currentLanguage
  },
  tenantInfo: state => {
    return state.tenantInfo;
  },
  clientVersion: state => {
    return state.clientVersion
  },
  isClientUsingOldVersion: state => {
    return state.isClientUsingOldVersion
  },
  branchIsSaglikNetActive: state => {
    return state.branchIsSaglikNetActive;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
