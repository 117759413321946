<template>
  <li class="nav-item">
    <a href="#" class="search-toggle iq-waves-effect">
      <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
      <span v-if="notifications && notifications.length > 0" class="bg-danger dots"></span>
    </a>
    <div class="iq-sub-dropdown">
      <div class="iq-card shadow-none m-0">
        <div class="iq-card-body p-0 ">
          <div v-if="notifications && notifications.length > 0" class="bg-primary p-3">
            <h5 class="mb-0 text-white">{{ $t('Notifications') }}<small class="badge  badge-light float-right pt-1">{{notifications.length}}</small></h5>
          </div>
          <div class="iq-sub-card" v-for="(item, index) in notifications" :key="index">
            <div v-if="item.customerID!=null" class="media align-items-center" style="cursor:pointer;">
              <div>
                <!--<img class="avatar-40 rounded" :src="item.image" alt="img">-->
                <b-form-checkbox v-model="item.selected" @change="completeNote(item.id)" class="notification-checkbox">
                </b-form-checkbox>
              </div>
              <div class="media-body ml-3" @click="openSummaryNotePage(item.customerID)">
                <h6 class="mb-0 ">{{ item.customerName }}</h6>
                <small class="float-right font-size-12">{{ item.date|dateFormat }}</small>
                <p class="mb-0">{{ item.notificationText }}</p>
              </div>
            </div>
            <div v-else class="media align-items-center">
              <div class="media-body ml-3">
                <small class="float-right font-size-12">{{ item.date|dateFormat }}}&nbsp;-&nbsp;{{ item.staffName }}</small>
                <p class="mb-0">{{ item.notificationText }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="notification_refresher" id="notification_refresher" @click="getNotifications()" />
    </div>
  </li>
</template>

<script>
  import { refreshSmartDuoData, refreshNotification } from '../../helpers/dataRefresher';
  import commonService from '../../services/common';
  import customerNoteService from '../../services/customerNote';

  export default {
    name: 'Notifications',
    components: {

    },
    data() {
      return {
        notifications: []
      }
    },
    methods: {
      getNotifications() {
        commonService.getNotifications().then((response) => {
          if (response) {
            this.notifications = response.personelNotifications.map(item => ({
              ...item,
              selected: false
            }));
          }
        })
      },
      completeNote(noteId) {
        customerNoteService.completeNote(noteId).then((response) => {
          if (response) {
            this.$toastr.success(this.$t('Success'));
            refreshSmartDuoData();
            refreshNotification();

            this.notifications = this.notifications.map(item => {
              if (item.id === noteId) {
                item.selected = false;
              }
              return item;
            });
          }
          else {
            this.$toastr.error(this.$t('Error'));
          }

          this.getNotifications();
        })

        this.notifications = this.notifications.map(item => {
          if (item.id === noteId) {
            item.selected = false;
          }
          return item;
        });
      },
      openSummaryNotePage(customerId) {
        if (this.$route.name == 'customerSummary.notes' && this.$route.params.customerId == customerId)
          return;
        else
          this.$router.push({ name: 'customerSummary.notes', params: { customerId: customerId } });
      },
    },
    mounted() {
      this.getNotifications();
    }
  }
</script>
