let translateUrl;

if (process.env.NODE_ENV === 'production') {
  translateUrl = 'https://hub.stellamedi.com/api/translation/getAllJson'
} else {
  translateUrl = 'https://localhost:7001/api/translation/getAllJson'
}

export default {
  webBaseURL: process.env.MIX_APP_URL,
  translateUrl
}
