import axios from './index'
import store from '../store/index'

export default {
  doLogin(loginResponse) {
    const token = loginResponse.token
    const user = loginResponse.user

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.common['BranchID'] = loginResponse.branchID;

    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('tenantId', loginResponse.tenantID)

    store.dispatch('Auth/setToken', token)
    store.dispatch('Auth/setCurrentUser', user)
    store.dispatch("Auth/setBranchIsSaglikNetActive", loginResponse.branchIsSaglikNetActive);
    store.dispatch('Auth/setTenantId', loginResponse.tenantID)
    store.dispatch('Auth/setCurrentBranch', { branchId: loginResponse.branchID, branchName: loginResponse.branchName, timezone: loginResponse.branchTimezone })

    if (loginResponse.workStartTime && loginResponse.workStartTime.length > 0) {
      store.dispatch('Calendar/setCalendarStartTime', loginResponse.workStartTime)
    }

    if (loginResponse.workEndTime && loginResponse.workEndTime.length > 0) {
      store.dispatch('Calendar/setCalendarEndTime', loginResponse.workEndTime)
    }

    return Promise.resolve(loginResponse);

  },
  login(userData) {
    return axios.post('/api/auth/login', userData)
      .then((response) => {
        if (response.data.token != null) {
          this.doLogin(response.data);
          Promise.resolve(response.data);
        } else {
          Promise.resolve(false)
        }
        return response.data
      }).catch((error) => {
        localStorage.removeItem('token');
        return Promise.reject(error)
      })
  },
  logout() {
    const token = store.getters['Auth/token'];
    if (token) {
      axios({
        url: "/api/auth/Logout",
        method: "POST"
      });
    }

    localStorage.removeItem('token');
    localStorage.removeItem('currentBranchId');
    localStorage.removeItem('currentBranchName');
    localStorage.removeItem('user');

    store.dispatch('Auth/setToken', '')
    store.dispatch('Auth/setCurrentUser', '')
    store.dispatch('Auth/setTenantId', '')
    store.dispatch('Auth/setCurrentBranch', { branchId: '', branchName: '', timezone: '' })

    window.vm.$router.push({ name: 'auth.sign-in' })
  },
  checkToken() {
    return axios.get('/api/Auth/CheckToken')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTenantInfo() {
    return axios.get('/common/getTenantInfo')
      .then((response) => {
        if (response.data.isFound) {
          store.dispatch('Auth/setTenantInfo', response.data);
          store.dispatch('Auth/setTenantId', response.data.tenantId)
          store.dispatch('Auth/setClientVersion', response.data.version);

          if (!store.getters['Auth/isLoggedIn']) {
            window.vm.$bus.$emit('UiLanguageChanged', response.data.language);
          }

          //$this.$store.state.isLoading = false;
        }
        else {
          store.dispatch('Auth/setTenantInfo', {});
          store.dispatch('Auth/setTenantId', 0)
          console.error(response.data.errorCode);
        }
      }).catch((error) => {
        store.dispatch('Auth/setTenantInfo', {});
        store.dispatch('Auth/setTenantId', 0)
        return Promise.reject(error);
      });
  }
}
