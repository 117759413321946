
function refreshSmartDuoData() {
  const buttons = document.getElementsByClassName('smart_table_refresher');
  for (var i = 0; i < buttons.length; i++)
    buttons[i].click();
}

function refreshMultimediaData() {
  const multimediaRefresher = document.getElementById('customerMultimedia_refresher');
  multimediaRefresher.click();
}

function refreshNotification() {
  var notificationRefresher = document.getElementById('notification_refresher');
  notificationRefresher.click();
}

function refreshCustomerSketch() {
  var customerSketchRefresher = document.getElementById('customerSketch_refresher');
  customerSketchRefresher.click();
}

function refreshTimeLineCalendar() {
  var timeLineCalendarRefresher = document.getElementById('timeLineCalendar_refresher');
  timeLineCalendarRefresher.click();
}

function refreshTimeGridCalendar() {
  var timeGridCalendarRefresher = document.getElementById('timeGridCalendar_refresher');
  timeGridCalendarRefresher.click();
}

function refreshGeneralCalendar() {
  var generalCalendarRefresher = document.getElementById('generalCalendar_refresher');
  generalCalendarRefresher.click();
}

function refreshWeightTracking() {
  var weightTrackingRefresher = document.getElementById('weightTracking_refresher');
  weightTrackingRefresher.click();
}
function sendSuccessfulFileSignal() {
  var sendFileSignal = document.getElementById('successfulFileSignal');
  sendFileSignal.click();
}

export { refreshSmartDuoData, refreshMultimediaData, refreshNotification, refreshCustomerSketch, refreshTimeLineCalendar, refreshTimeGridCalendar, refreshGeneralCalendar, refreshWeightTracking, sendSuccessfulFileSignal }
