import axios from './index'

export default {
  getStatusList() {
    return axios.get('/data/shared/status')
      .then(response => {
        const translatedResponse = response.data.map(status => {
          status.name = window.vm.$t(status.name);
          return status;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getNoteTypeList() {
    return axios.get('/data/common/customerNoteType')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewCustomerNote(model) {
    return axios.post('/customerNote/insert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  completeNote(noteId) {
    return axios.get('/customerNote/updateNoteCompletionAsync?customerNoteId=' + noteId + "&isCompleted=" + true)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
