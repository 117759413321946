import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Layout from '../layouts/Layout'
import MeetingLayout from '../layouts/MeetingLayout'
import BlankLayout from '../layouts/BlankLayout'
import AuthLayout from '../layouts/AuthLayout'
import store from '../../src/store/index'

const customerSummaryChildRoutes = (prop, mode) => [
  {
    path: 'details',
    name: prop + '.details',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryDetail' },
    component: () => import(/* webpackChunkName: "customerSummary" */ '@/views/Pages/CustomerSummary/Summary_Details.vue'),
    props: (route) => ({
      customerId: route.params.customerId
    })
  },
  {
    path: 'edit',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryEdit' },
    component: () => import(/* webpackChunkName: "customerSummary" */ '@/views/Pages/Customer/NewCustomer.vue'),
    props: (route) => ({ customerId: route.params.customerId })
  },
  {
    path: 'appointment',
    name: prop + '.appointments',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryAppointment' },
    component: () => import(/* webpackChunkName: "smartduo" */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: (route) => ({
      filterOptionsUrl: "/Appointment/Vue",
      customRenderFor: "CustomerSummary",
      customerId: route.params.customerId
    }),
    children: [{
      path: ':appointmentId/edit',
      name: 'appointment.modalEditFromCustomerSummary',
      meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryAppointment', isModal: true, size: 'xl' },
      component: () => import(/* webpackChunkName: "appointment" */ "@/views/Pages/Appointment/NewAppointment.vue"),
      beforeEnter: (to, from, next) => {
        const matchedComponents = to.matched.find(z => z.name == to.name);
        const params = { appointmentId: to.params.appointmentId, isModal: to.meta.isModal }
        store.dispatch('Modal/setCurrentModalId', 'EditAppointment');
        store.dispatch('Modal/setCurrentModalTitle', 'EditAppointment');
        store.dispatch('Modal/setComponentProps', params);
        store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
        next();
      }
    }]
  },
  {
    path: 'note',
    name: prop + '.notes',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryNote' },
    component: () => import(/* webpackChunkName: "smartduo" */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: (route) => ({
      filterOptionsUrl: "/CustomerNote/Vue",
      customRenderFor: "CustomerSummary",
      customerId: route.params.customerId
    }),
    children: [{
      path: 'new',
      name: 'customerNote.new',
      meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryNote', isModal: true, size: 'lg' },
      component: () => import(/* webpackChunkName: "customer" */ "@/views/Pages/Customer/NewCustomerNote.vue"),
      beforeEnter: (to, from, next) => {
        const matchedComponents = to.matched.find(z => z.name == to.name);
        store.dispatch('Modal/setCurrentModalId', 'NewCustomerNote');
        store.dispatch('Modal/setCurrentModalTitle', 'NewNote');
        store.dispatch('Modal/setComponentProps', to.params);
        store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
        next();
      }
    }]
  },
  {
    path: 'form',
    name: prop + '.forms',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryForm' },
    component: () => import(/* webpackChunkName: "customerSummary" */ '@/views/Pages/CustomerSummary/Summary_Forms.vue'),
    props: (route) => ({ customerId: route.params.customerId }),
    children: [
      {
        path: 'weightTracking/new',
        name: 'weightTracking.new',
        meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryForm', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: "weightTracking" */ "@/views/Pages/CustomerWeightTracking/NewWeightTracking.vue"),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'NewWeightTracking');
          store.dispatch('Modal/setCurrentModalTitle', 'NewWeightTracking');
          store.dispatch('Modal/setComponentProps', to.params);
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      },
      {
        path: 'weightTracking/edit/:weightTrackingId',
        name: 'weightTracking.edit',
        meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryForm', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: "weightTracking" */ "@/views/Pages/CustomerWeightTracking/NewWeightTracking.vue"),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'EditWeightTracking');
          store.dispatch('Modal/setCurrentModalTitle', 'Edit');
          store.dispatch('Modal/setComponentProps', to.params);
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      }
    ]
  },
  {
    path: 'multimedia',
    name: prop + '.multimedias',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryMultimedia' },
    component: () => import(/* webpackChunkName: "multimedia" */ '@/views/Pages/CustomerSummary/Summary_Multimedias.vue'),
    props: (route) => ({ customerId: route.params.customerId }),
    children: [{
      path: 'fileUpload',
      name: 'multimedia.fileUpload',
      meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryMultimedia', isModal: true, size: 'lg' },
      component: () => import(/* webpackChunkName: "multimedia" */ "@/views/Pages/Multimedia/FileUpload.vue"),
      beforeEnter: (to, from, next) => {
        const matchedComponents = to.matched.find(z => z.name == to.name);
        store.dispatch('Modal/setCurrentModalId', 'NewFileOrPhoto');
        store.dispatch('Modal/setCurrentModalTitle', 'NewFileOrPhoto');
        store.dispatch('Modal/setComponentProps', to.params);
        store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
        next();
      }
    }]
  },
  {
    path: 'bill',
    name: prop + '.bills',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryBill' },
    component: () => import(/* webpackChunkName: "customerSummary" */ '@/views/Pages/CustomerSummary/Summary_Accountings.vue'),
    props: (route) => ({ customerId: route.params.customerId }),
    children: [{
      path: 'payFromDebt/:accountingDebtId',
      name: 'customerSummary.payFromDebt',
      meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryBill', isModal: true, size: 'lg' },
      component: () => import(/* webpackChunkName: "accounting" */ "@/views/Pages/Accounting/PayFromDebt.vue"),
      beforeEnter: (to, from, next) => {
        const matchedComponents = to.matched.find(z => z.name == to.name);
        store.dispatch('Modal/setCurrentModalId', 'PayFromDebt');
        store.dispatch('Modal/setCurrentModalTitle', 'AccountingPayFromDebt');
        store.dispatch('Modal/setComponentProps', to.params);
        store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
        next();
      }
    }]
  },
  {
    path: 'diagnose',
    name: prop + '.diagnoses',
    meta: { dark: mode, auth: true, layout: Layout, name: 'CustomerSummary', childName: 'CustomerSummaryDiagnose' },
    component: () => import(/* webpackChunkName: "smartduo" */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: (route) => ({
      filterOptionsUrl: "/CustomerDiagnose/Vue",
      customRenderFor: "CustomerSummary",
      customerId: route.params.customerId
    })
  },
]

const appointmentChildRoutes = (prop, mode) => [
  {
    path: ':appointmentId/edit',
    name: prop + '.modalEdit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Appointment', isModal: true, size: 'xl' },
    component: () => import(/* webpackChunkName: 'appointment' */ '@/views/Pages/Appointment/NewAppointment.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      const params = { appointmentId: to.params.appointmentId, isModal: to.meta.isModal }
      store.dispatch('Modal/setCurrentModalId', 'EditAppointment');
      store.dispatch('Modal/setCurrentModalTitle', 'EditAppointment');
      store.dispatch('Modal/setComponentProps', params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const calendarChildRoutes = (prop, mode) => [
  {
    path: '',
    name: 'calendar',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/Calendars.vue")
  },
  {
    path: 'general',
    name: 'calendar.general',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar', childName: 'CalendarGeneral' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/GeneralCalendar.vue")
  },
  {
    path: 'staff/classic',
    name: 'calendar.staffClassic',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar', childName: 'CalendarStaffClassic' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/TimeGridCalendar.vue"),
    props: {
      mode: 's'
    }
  },
  {
    path: 'staff/grid',
    name: 'calendar.staffGrid',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar', childName: 'CalendarStaffGrid' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/TimeLineCalendar.vue"),
    props: {
      mode: 's'
    }
  },
  {
    path: 'room/classic',
    name: 'calendar.roomClassic',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar', childName: 'CalendarRoomClassic' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/TimeGridCalendar.vue"),
    props: {
      mode: 'br'
    }
  },
  {
    path: 'room/grid',
    name: 'calendar.roomGrid',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Calendar', childName: 'CalendarRoomGrid' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/TimeLineCalendar.vue"),
    props: {
      mode: 'br'
    }
  }
]

const accountingChildRoutes = (prop, mode) => [{
  path: 'payFromDebt/:accountingDebtId',
  name: 'accounting.payFromDebt',
  meta: { dark: mode, auth: true, layout: Layout, name: 'AccountingCustomerDebtList', isModal: true, size: 'lg' },
  component: () => import(/* webpackChunkName: "accounting" */ "@/views/Pages/Accounting/PayFromDebt.vue"),
  beforeEnter: (to, from, next) => {
    const matchedComponents = to.matched.find(z => z.name == to.name);
    store.dispatch('Modal/setCurrentModalId', 'PayFromDebt');
    store.dispatch('Modal/setCurrentModalTitle', 'AccountingPayFromDebt');
    store.dispatch('Modal/setComponentProps', to.params);
    store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
    next();
  }
}]

const settingsChildRoutes = (prop, mode) => [
  {
    path: 'generalSettings',
    name: prop + '.general',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Settings', childName: 'GeneralSettings' },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Pages/Management/GeneralSettings.vue')
  },
  {
    path: 'customerSettings',
    name: prop + '.customer',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Settings', childName: 'CustomerSettings' },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Pages/Management/CustomerSettings.vue')
  },
  {
    path: 'formSettings',
    name: prop + '.form',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Settings', childName: 'FormSettings' },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Pages/Management/FormSettings.vue')
  },
  {
    path: 'docplannerSettings',
    name: prop + '.docplanner',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Settings', childName: 'DocplannerSettings' },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Pages/Management/DocplannerSettings.vue')
  }
]

const definitionsChildRoutes = (mode) => [
  {
    path: 'bankInfo',
    name: 'bankInfo.list',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BankInfo' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/BankInfo/Vue'
    },
    children: [
      {
        path: 'new',
        name: 'bankInfo.new',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BankInfo', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'bankInfo' */ '@/views/Pages/Definitions/NewBankInfo.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'NewBankInfo');
          store.dispatch('Modal/setCurrentModalTitle', 'NewBankInfo');
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      },
      {
        path: 'edit/:bankInfoId',
        name: 'bankInfo.edit',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BankInfo', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'bankInfo' */ '@/views/Pages/Definitions/NewBankInfo.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'EditBankInfo');
          store.dispatch('Modal/setCurrentModalTitle', 'Edit');
          store.dispatch('Modal/setComponentProps', to.params);
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      }
    ]
  },
  {
    path: 'formTemplate',
    name: 'formTemplate.list',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'CustomerFormTemplate' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/CustomerFormTemplate/Vue'
    },
    children: [
      {
        path: 'new',
        name: 'formTemplate.new',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'CustomerFormTemplate', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'customerFormTemplate' */ '@/views/Pages/CustomerFormTemplate/NewCustomerFormTemplate.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'NewCustomerFormTemplate');
          store.dispatch('Modal/setCurrentModalTitle', 'NewCustomerFormTemplate');
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      },
      {
        path: 'edit/:customerFormId',
        name: 'formTemplate.edit',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'CustomerFormTemplate', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'customerFormTemplate' */ '@/views/Pages/CustomerFormTemplate/NewCustomerFormTemplate.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'EditCustomerFormTemplate');
          store.dispatch('Modal/setCurrentModalTitle', 'Edit');
          store.dispatch('Modal/setComponentProps', to.params);
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      }
    ]
  },
  {
    path: 'consentForm',
    name: 'consentForm.list',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'ConsentForm' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/ConsentForm/Vue'
    },
  },
  {
    path: 'customConsentForm',
    name: 'customConsentForm.list',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'CustomConsentForm' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/api/CustomConsentForm/Vue'
    },
  },
  {
    path: 'branchRoom',
    name: 'branchRoom.list',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BranchRoom' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/BranchRoom/Vue'
    },
    children: [
      {
        path: 'new',
        name: 'branchRoom.new',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BranchRoom', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'branchRoom' */ '@/views/Pages/Definitions/NewBranchRoom.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'NewBranchRoom');
          store.dispatch('Modal/setCurrentModalTitle', 'NewBranchRoom');
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      },
      {
        path: 'edit/:branchRoomId',
        name: 'branchRoom.edit',
        meta: { dark: mode, auth: true, layout: Layout, name: 'Definitions', childName: 'BranchRoom', isModal: true, size: 'lg' },
        component: () => import(/* webpackChunkName: 'branchRoom' */ '@/views/Pages/Definitions/NewBranchRoom.vue'),
        beforeEnter: (to, from, next) => {
          const matchedComponents = to.matched.find(z => z.name == to.name);
          store.dispatch('Modal/setCurrentModalId', 'EditBranchRoom');
          store.dispatch('Modal/setCurrentModalTitle', 'Edit');
          store.dispatch('Modal/setComponentProps', to.params);
          store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
          next();
        }
      }
    ]
  }
]

const staffChildRoutes = (prop, mode) => [
  {
    path: 'staff-new',
    name: prop + '.new',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Staff', isModal: true, size: "xl" },
    component: () => import(/* webpackChunkName: 'staff' */ '@/views/Pages/Staff/NewStaff.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'NewStaff');
      store.dispatch('Modal/setCurrentModalTitle', 'NewStaff');
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'edit/:staffId',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Staff', isModal: true, size: "xl" },
    component: () => import(/* webpackChunkName: 'staff' */ '@/views/Pages/Staff/NewStaff.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditStaff');
      store.dispatch('Modal/setCurrentModalTitle', 'Edit');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'passwordSettings/:staffId',
    name: prop + '.passwordSettings',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Staff', isModal: true },
    component: () => import(/* webpackChunkName: 'staff' */ '@/views/Pages/Staff/PasswordSettings.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'PasswordSettings');
      store.dispatch('Modal/setCurrentModalTitle', 'PasswordSettings');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const staffProfileChildRoutes = (prop, mode) => [
  {
    path: ':staffId/passwordSettings',
    name: prop + '.passwordSettings',
    meta: { dark: mode, auth: true, layout: Layout, name: 'StaffProfile', isModal: true },
    component: () => import(/* webpackChunkName: "staff" */ "@/views/Pages/Staff/PasswordSettings.vue"),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'PasswordSettings');
      store.dispatch('Modal/setCurrentModalTitle', 'PasswordSettings');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const serviceGroupChildRoutes = (prop, mode) => [
  {
    path: 'new',
    name: prop + '.new',
    meta: { dark: mode, auth: true, layout: Layout, name: 'ServiceGroup', isModal: true },
    component: () => import(/* webpackChunkName: 'serviceGroup' */ '@/views/Pages/Service/NewServiceGroup.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'NewServiceGroup');
      store.dispatch('Modal/setCurrentModalTitle', 'NewServiceGroup');
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'edit/:serviceGroupId',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'ServiceGroup', isModal: true },
    component: () => import(/* webpackChunkName: 'serviceGroup' */ '@/views/Pages/Service/NewServiceGroup.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditServiceGroup');
      store.dispatch('Modal/setCurrentModalTitle', 'Edit');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const serviceChildRoutes = (prop, mode) => [
  {
    path: 'new',
    name: prop + '.new',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Service', isModal: true, size: "lg" },
    component: () => import(/* webpackChunkName: 'service' */ '@/views/Pages/Service/NewService.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'NewService');
      store.dispatch('Modal/setCurrentModalTitle', 'NewService');
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'edit/:serviceId',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Service', isModal: true, size: "lg" },
    component: () => import(/* webpackChunkName: 'service' */ '@/views/Pages/Service/NewService.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditService');
      store.dispatch('Modal/setCurrentModalTitle', 'Edit');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const productTypeChildRoutes = (prop, mode) => [
  {
    path: 'new',
    name: prop + '.new',
    meta: { dark: mode, auth: true, layout: Layout, name: 'ProductType', isModal: true },
    component: () => import(/* webpackChunkName: 'productType' */ '@/views/Pages/Product/NewProductType.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'NewProductType');
      store.dispatch('Modal/setCurrentModalTitle', 'NewProductType');
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'edit/:productTypeId',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'ProductType', isModal: true },
    component: () => import(/* webpackChunkName: 'productType' */ '@/views/Pages/Product/NewProductType.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditProductType');
      store.dispatch('Modal/setCurrentModalTitle', 'Edit');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const productChildRoutes = (prop, mode) => [
  {
    path: 'new',
    name: prop + '.new',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Product', isModal: true, size: "lg" },
    component: () => import(/* webpackChunkName: 'product' */ '@/views/Pages/Product/NewProduct.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'NewProduct');
      store.dispatch('Modal/setCurrentModalTitle', 'NewProduct');
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'edit/:productId',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Product', isModal: true, size: "lg" },
    component: () => import(/* webpackChunkName: 'product' */ '@/views/Pages/Product/NewProduct.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditProduct');
      store.dispatch('Modal/setCurrentModalTitle', 'Edit');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  },
  {
    path: 'stock/:productId',
    name: prop + '.stock',
    meta: { dark: mode, auth: true, layout: Layout, name: 'Product', isModal: true, size: "xl" },
    component: () => import(/* webpackChunkName: 'product' */ '@/views/Pages/Product/ProductStock.vue'),
    beforeEnter: (to, from, next) => {
      const matchedComponents = to.matched.find(z => z.name == to.name);
      store.dispatch('Modal/setCurrentModalId', 'EditProductStock');
      store.dispatch('Modal/setCurrentModalTitle', 'ProductStock');
      store.dispatch('Modal/setComponentProps', to.params);
      store.dispatch('Modal/setDynamicComponent', matchedComponents.components.default);
      next();
    }
  }
]

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'home.dashboard',
    meta: { auth: true, layout: Layout, name: 'Dashboard' },
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Pages/Home/Dashboard.vue')
  },
  {
    path: '/auth/sign-in',
    name: 'auth.sign-in',
    meta: { auth: true, layout: AuthLayout, name: 'SignIn' },
    component: () => import(/* webpackChunkName: 'auth' */ '@/views/AuthPages/SignIn.vue')
  },
  {
    path: '/auth/sign-in-sso/:sid',
    name: 'auth.sign-in-sso',
    meta: { auth: true, layout: AuthLayout, name: 'SignInSso' },
    component: () => import(/* webpackChunkName: 'auth' */ '@/components/shared/SsoHandler.vue'),
    props: true
  },
  {
    path: '/treatmentSketch/new-from-multimedia/:multimediaId/:customerId',
    name: 'sketch.multimedia',
    meta: { auth: true, layout: BlankLayout, name: 'CustomerSketch', isLayoutless: true },
    component: () => import(/* webpackChunkName: "treatmentSketch" */ "@/views/Pages/CustomerSketch/CustomerSketch.vue"),
    props: true
  },
  {
    path: '/treatmentSketch/new-from-sketch/:sketchTemplateId/:customerId',
    name: 'sketch.new',
    meta: { auth: true, layout: BlankLayout, name: 'CustomerSketch', isLayoutless: true },
    component: () => import(/* webpackChunkName: "treatmentSketch" */ "@/views/Pages/CustomerSketch/CustomerSketch.vue"),
    props: true
  },
  {
    path: '/treatmentSketch/new-from-customersketch/:customerSketchId/:customerId',
    name: 'sketch.edit',
    meta: { auth: true, layout: BlankLayout, name: 'CustomerSketch', isLayoutless: true },
    component: () => import(/* webpackChunkName: "treatmentSketch" */ "@/views/Pages/CustomerSketch/CustomerSketch.vue"),
    props: true,
  },
  {
    path: '/customer',
    name: 'customer.list',
    meta: { auth: true, layout: Layout, name: 'Customer' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Customer/Vue'
    }
  },
  {
    path: '/customer/import',
    name: 'customer.import',
    meta: { auth: true, layout: Layout, name: 'CustomerImport' },
    component: () => import(/* webpackChunkName: "customer" */ "@/views/Pages/Customer/CustomerImport.vue")
  },
  {
    path: '/customer-new',
    name: 'customer.new',
    meta: { auth: true, layout: Layout, name: 'NewCustomer' },
    component: () => import(/* webpackChunkName: 'customer' */ '@/views/Pages/Customer/NewCustomer.vue')
  },
  {
    path: '/customer/:customerId',
    name: 'customerSummary',
    props: true,
    meta: { auth: true, layout: Layout, name: 'CustomerSummary' },
    component: () => import(/* webpackChunkName: "customerSummary" */ '@/views/Pages/CustomerSummary/CustomerSummary.vue'),
    children: customerSummaryChildRoutes('customerSummary')
  },
  {
    path: '/customer/:customerId/diagnose/new',
    name: 'customerDiagnose.new',
    meta: { auth: true, layout: Layout, name: 'CustomerDiagnose' },
    component: () => import(/* webpackChunkName: "customerSummaryDiagnose" */ "@/views/Pages/Customer/NewCustomerDiagnose.vue"),
    props: (route) => ({ customerId: route.params.customerId })
  },
  {
    path: '/customer/:customerId/diagnose/edit/:id',
    name: 'customerDiagnose.edit',
    meta: { auth: true, layout: Layout, name: 'CustomerDiagnose' },
    component: () => import(/* webpackChunkName: "customerSummaryDiagnose" */ "@/views/Pages/Customer/NewCustomerDiagnose.vue"),
    props: (route) => ({ customerId: route.params.customerId, id: route.params.id })
  },
  {
    path: '/customerNote',
    name: 'customerNote.list',
    meta: { auth: true, layout: Layout, name: 'CustomerNote' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/CustomerNote/Vue'
    }
  },
  {
    path: '/appointment',
    name: 'appointment.list',
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    meta: { auth: true, layout: Layout, name: 'Appointment' },
    props: {
      filterOptionsUrl: '/Appointment/Vue'
    },
    children: appointmentChildRoutes('appointment')
  },
  {
    path: '/appointment-new',
    name: 'appointment.new',
    meta: { auth: true, layout: Layout, name: 'NewAppointment' },
    component: () => import(/* webpackChunkName: 'appointment' */ '@/views/Pages/Appointment/NewAppointment.vue')
  },
  {
    path: '/appointment-new/:customerId',
    name: 'appointment.newFromCustomerSummary',
    meta: { auth: true, layout: Layout, name: 'NewAppointment' },
    component: () => import(/* webpackChunkName: "appointment" */ "@/views/Pages/Appointment/NewAppointment.vue"),
    props: true
  },
  {
    path: '/appointment-edit/:appointmentId',
    name: 'appointment.edit',
    props: true,
    meta: { auth: true, layout: Layout, name: 'EditAppointment' },
    component: () => import(/* webpackChunkName: 'appointment' */ '@/views/Pages/Appointment/NewAppointment.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: { auth: true, layout: Layout, name: 'Calendar' },
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/Pages/Calendar/Calendars.vue"),
    children: calendarChildRoutes('calendar')
  },
  {
    path: '/bill',
    name: 'bill.list',
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    meta: { auth: true, layout: Layout, name: 'Bill' },
    props: {
      filterOptionsUrl: '/Bill/Vue'
    }
  },
  {
    path: '/bill-new',
    name: 'bill.new',
    meta: { auth: true, layout: Layout, name: 'NewBill' },
    component: () => import(/* webpackChunkName: 'bill' */ '@/views/Pages/Bill/NewBill.vue')
  },
  {
    path: '/bill-new/:customerId',
    name: 'bill.newFromCustomerSummary',
    meta: { auth: true, layout: Layout, name: 'NewBillFromCustomerSummary' },
    component: () => import(/* webpackChunkName: "bill" */ "@/views/Pages/Bill/NewBill.vue"),
    props: true
  },
  {
    path: '/bill-new-from-appointment/:customerId/:appointmentId',
    name: 'bill.newFromAppointment',
    meta: { auth: true, layout: Layout, name: 'NewBillFromAppointment' },
    component: () => import(/* webpackChunkName: "bill" */ "@/views/Pages/Bill/NewBill.vue"),
    props: true
  },
  {
    path: '/bill-edit/:billId',
    name: 'bill.edit',
    meta: { auth: true, layout: Layout, name: 'EditBill' },
    component: () => import(/* webpackChunkName: 'bill' */ '@/views/Pages/Bill/NewBill.vue'),
    props: true
  },
  {
    path: '/accounting/summary',
    name: 'accounting.summary',
    meta: { auth: true, layout: Layout, name: 'AccountingSummary' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Accounting/VueSummary'
    }
  },
  {
    path: '/accounting/customerDebtList',
    name: 'accounting.customerDebtList',
    meta: { auth: true, layout: Layout, name: 'AccountingCustomerDebtList' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Accounting/VueCustomerDebt'
    },
    children: accountingChildRoutes('accounting')
  },
  {
    path: '/reporting/sales',
    name: 'report.sales',
    meta: { auth: true, layout: Layout, name: 'SalesReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueSalesReport'
    }
  },
  {
    path: '/reporting/revenue',
    name: 'report.revenue',
    meta: { auth: true, layout: Layout, name: 'RevenueReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueRevenueReport'
    }
  },
  {
    path: '/reporting/obesityTracking',
    name: 'report.obesityTracking',
    meta: { auth: true, layout: Layout, name: 'ObesityTrackingReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueCustomerObesityTrackingReport'
    }
  },
  {
    path: '/reporting/appointmentHeatMap',
    name: 'report.appointmentHeatMap',
    meta: { auth: true, layout: Layout, name: 'AppointmentHeatMap' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueAppointmentHeatmap'
    }
  },
  {
    path: '/reporting/helperStaffRole',
    name: 'report.helperStaffRole',
    meta: { auth: true, layout: Layout, name: 'HelperStaffRoleReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueHelperStaffRoleReport'
    }
  },
  {
    path: '/reporting/treatmentTracking',
    name: 'report.treatmentTracking',
    meta: { auth: true, layout: Layout, name: 'TreatmentTrackingReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueTreatmentTrackingReport'
    }
  },
  {
    path: '/reporting/productStock',
    name: 'report.stock',
    meta: { auth: true, layout: Layout, name: 'StockReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueStockReport'
    }
  },
  {
    path: '/reporting/customerForm',
    name: 'report.customerForm',
    meta: { auth: true, layout: Layout, name: 'CustomerFormReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueCustomerFormReport'
    }
  },
  {
    path: '/reporting/customerFormSchedule',
    name: 'report.customerFormSchedule',
    meta: { auth: true, layout: Layout, name: 'CustomerFormSchedule' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueCustomerFormScheduleReport'
    }
  },
  {
    path: '/reporting/customerFormAnswerAnalysis',
    name: 'report.customerFormAnswerAnalysis',
    meta: { auth: true, layout: Layout, name: 'CustomerFormAnswerAnalysis' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Reporting/VueCustomerFormAnswerAnalysis',
      customComponentPath: 'views/Pages/Reporting/CustomerFormAnswerAnalysis'
    }
  },
  {
    path: '/reporting/appointmentTrend',
    name: 'report.appointmentTrendReport',
    meta: { auth: true, layout: Layout, name: 'AppointmentTrendReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/api/TrendReporting/Vue',
      customComponentPath: 'views/Pages/Reporting/AppointmentTrend'
    }
  },
  {
    path: '/reporting/saleTrend',
    name: 'report.saleTrendReport',
    meta: { auth: true, layout: Layout, name: 'SaleTrendReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/api/TrendReporting/Vue',
      customComponentPath: 'views/Pages/Reporting/SaleTrend'
    }
  },
  {
    path: '/reporting/customerTrend',
    name: 'report.customerTrendReport',
    meta: { auth: true, layout: Layout, name: 'CustomerTrendReport' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/api/TrendReporting/Vue',
      customComponentPath: 'views/Pages/Reporting/CustomerTrend'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { auth: true, layout: Layout, name: 'Settings' },
    component: () => import(/* webpackChunkName: 'leftmenu' */ '@/views/Pages/Shared/LeftMenu.vue'),
    props: {
      dataUrl: '/api/VegaCommon/GetManagementMenu',
      startRoute: 'settings.general',
      title: 'Settings'
    },
    children: settingsChildRoutes('settings')
  },
  {
    path: '/staff',
    name: 'staff.list',
    meta: { auth: true, layout: Layout, name: 'Staff' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Staff/Vue'
    },
    children: staffChildRoutes('staff')
  },
  {
    path: '/staff/profile',
    name: 'staff.profile',
    meta: { auth: true, layout: Layout, name: 'StaffProfile' },
    component: () => import(/* webpackChunkName: 'staff' */ '@/views/Pages/Staff/StaffProfile.vue'),
    children: staffProfileChildRoutes('staffProfile')
  },
  {
    path: '/serviceGroup',
    name: 'serviceGroup.list',
    meta: { auth: true, layout: Layout, name: 'ServiceGroup' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/ServiceGroup/Vue'
    },
    children: serviceGroupChildRoutes('serviceGroup')
  },
  {
    path: '/service',
    name: 'service.list',
    meta: { auth: true, layout: Layout, name: 'Service' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Service/Vue'
    },
    children: serviceChildRoutes('service')
  },
  {
    path: '/productType',
    name: 'productType.list',
    meta: { auth: true, layout: Layout, name: 'ProductType' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/ProductType/Vue'
    },
    children: productTypeChildRoutes('productType')
  },
  {
    path: '/product',
    name: 'product.list',
    meta: { auth: true, layout: Layout, name: 'Product' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: {
      filterOptionsUrl: '/Product/Vue'
    },
    children: productChildRoutes('product')
  },
  {
    path: '/definitions',
    name: 'definitions',
    meta: { auth: true, layout: Layout, name: 'Definitions' },
    component: () => import(/* webpackChunkName: 'leftmenu' */ '@/views/Pages/Shared/LeftMenu.vue'),
    props: {
      dataUrl: '/api/VegaCommon/GetDefinitionsMenu',
      startRoute: 'bankInfo.list',
      title: 'Definitions'
    },
    children: definitionsChildRoutes()
  },
  {
    path: '/definitions/customConsentForm/new',
    name: 'customConsentForm.new',
    meta: { auth: true, layout: Layout, name: 'NewCustomConsentForm' },
    component: () => import(/* webpackChunkName: "customConsentForm"*/ "@/views/Pages/CustomConsentForm/NewCustomConsentForm.vue"),
  },
  {
    path: '/definitions/customConsentForm/edit/:customConsentFormId',
    name: 'customConsentForm.edit',
    meta: { auth: true, layout: Layout, name: 'EditCustomConsentForm' },
    component: () => import(/* webpackChunkName: "customConsentForm"*/ "@/views/Pages/CustomConsentForm/NewCustomConsentForm.vue"),
    props: true
  },
  {
    path: '/definitions/customConsentForm/preview/:customConsentFormId',
    name: 'customConsentForm.preview',
    meta: { auth: true, layout: Layout, name: 'PreviewCustomConsentForm' },
    component: () => import(/* webpackChunkName: "customConsentForm"*/ "@/views/Pages/CustomConsentForm/CustomConsentFormPreview.vue"),
    props: true
  },
  {
    path: '/customer/:customerId/customerForm/new/:templateId',
    name: 'customerForm.new',
    meta: { auth: false, layout: BlankLayout, name: 'NewCustomForm', isLayoutless: true },
    component: () => import(/* webpackChunkName: "customerForm" */ '@/views/Pages/CustomerForm/CustomerForm.vue'),
    props: true,
  },
  {
    path: '/customer/:customerId/customerForm/edit/:customerFormId',
    name: 'customerForm.edit',
    meta: { auth: false, layout: BlankLayout, name: 'EditCustomForm', isLayoutless: true },
    component: () => import(/* webpackChunkName: "customerForm" */ '@/views/Pages/CustomerForm/CustomerForm.vue'),
    props: true,
  },
  {
    path: '/customer/:customerId/customerForm/:templateId/:branchId',
    name: 'customerForm.fromMessage',
    meta: { auth: false, layout: BlankLayout, name: 'CustomerFormFromMessage', isLayoutless: true },
    component: () => import(/* webpackChunkName: "customerForm" */'@/views/Pages/CustomerForm/CustomerForm.vue'),
    props: true,
  },
  {
    path: '/eNabiz',
    name: 'eNabiz.transfer',
    meta: { auth: true, layout: Layout, name: 'ENabizTransfer' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: () => ({
      filterOptionsUrl: "/Sagliknet/Vue",
      customComponentPath: 'views/Pages/ENabiz/ENabizTransfer'
    }),
  },
  {
    path: '/eNabizLogs',
    name: 'eNabiz.logs',
    meta: { auth: true, layout: Layout, name: 'ENabizLogs' },
    component: () => import(/* webpackChunkName: 'smartduo' */ '@/views/Pages/Shared/SmartDuo.vue'),
    props: () => ({
        filterOptionsUrl: "/Sagliknet/LogVue"
    }),
  },
  {
    path: '/meeting/init/:appointmentId/',
    name: '/meetingInit',
    component: () => import(/* webpackChunkName: "meeting" */ "@/views/Pages/Meeting/MeetingComponent.vue"),
    props: true,
    meta: {
      isHost: true, auth: true, layout: Layout, name: 'OnlineMeetingHost', isLayoutless: true
    }
  },
  {
    path: '/joinToMeeting/:appointmentId',
    name: 'joinToMeeting',
    component: () => import(/* webpackChunkName: "meeting" */ "@/views/Pages/Meeting/MeetingComponent.vue"),
    props: true,
    meta: {
      isHost: false, auth: false, layout: MeetingLayout, name: 'OnlineMeetingHost', isLayoutless: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
