<template>
  <div>
    <section class="sign-in-page">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <a class="sign-in-logo mb-3" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
              <img :src="layoutBackgroundImage" class="img-fluid mb-4 d-none d-md-block" alt="logo">
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
  import logo from '../assets/images/logo-full-lg.png'
  import layoutBackgroundImage from '../assets/images/login/3.png'
  import { xray } from '../config/pluginInit'
  import bgImage from '../assets/images/login/2.png'
  export default {
    name: 'AuthLayout',
    mounted() {
      xray.index()
    },
    data() {
      return {
        slickOptions: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        },
        logo: logo,
        layoutBackgroundImage: layoutBackgroundImage,
        bgImageURL: bgImage
      }
    }
  }
</script>
