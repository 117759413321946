<template>
  <div class="iq-search-bar">
    <div class="searchbox" :style="dataHiddenName == 'LayoutCustomerID' ? '' : 'width:100%'">
      <input type="text"
             :class="dataHiddenName == 'LayoutCustomerID' ? 'search-input' : 'form-control'"
             :placeholder="placeholderText"
             v-model="name"
             @keydown.enter="enter"
             @keydown.down="down"
             @keydown.up="up"
             @input="change"
             :disabled="isDisabledInput"
             autocomplete="off"
             :id="dataHiddenName">
      <span class="search-link"><font-awesome-icon icon="fa fa-search" /></span>
      <ul class="dropdown-menu"
          v-bind:class="{'inner show':openSuggestion}"
          style="width:100%">

        <li v-for="(suggestion,index) in matches"
            :key="index"
            :style="isActive(index) ? styleObject : ''"
            @click="suggestionClick(index)">

          <router-link class="dropdown-item"
                       v-if="dataHiddenName == 'LayoutCustomerID'"
                       :to="{ name: 'customerSummary.details', params: { customerId: suggestion.id } }">

            <div class="row" style="border-bottom: 1px solid #ccc !important;">
              <div class="col-md-12" style="font-size:14px !important; font-weight: bold !important">
                {{ suggestion.name }}
              </div>
              <div class="col-md-1">
                <font-awesome-icon icon="fa fa-square" v-bind:class="suggestion.customerTypeColor" /> {{ suggestion.customerTypeName }}
              </div>
              <div v-if="suggestion.phone && suggestion.phone.length > 0" class="col-md-6">
                <font-awesome-icon icon="fa fa-phone" /> {{ suggestion.phone }}
              </div>
              <div v-if="suggestion.cardNo && suggestion.cardNo.length > 0" class="col-md-5"
                   :title="cardNoTitleText(suggestion.displayNumber)">
                <font-awesome-icon icon="fa fa-folder-open" /> {{ suggestion.cardNo }}
              </div>
              <div v-else-if="suggestion.displayNumber!=null && !(suggestion.cardNo && suggestion.cardNo.length > 0)" class="col-md-5">
                <i style="font-weight: bold !important; vertical-align: sub;">
                  {{ cardNoTitleText(suggestion.displayNumber) }}
                </i>
              </div>
            </div>

          </router-link>
          <a v-else class="dropdown-item">
            {{ suggestion.name }}
            <span v-if="suggestion.phone && suggestion.phone.length > 0"> ({{ suggestion.phone }})</span>
            <span v-if="suggestion.cardNo && suggestion.cardNo.length > 0">({{ suggestion.cardNo }})</span>
          </a>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import layoutService from '../../services/layout'

  export default {
    name: 'AutoCompolete',
    props: {
      dataUrl: String,
      dataHiddenName: String,
      dataId: String,
      isDisabledInput: Boolean
    },
    data() {
      return {
        id: '',
        open: false,
        suggestions: [],
        name: '',
        current: 0,
        styleObject: {
          color: '#5867dd',
          backgroundColor: '#f5f6fc'
        }
      };
    },
    methods: {
      enter() {
        this.name = this.matches[this.current].name;
        this.id = this.matches[this.current].id;
        this.open = false;
        this.suggestions = [];
      },
      up() {
        if (this.current > 0)
          this.current--;
      },
      down() {
        if (this.current < this.matches.length - 1) {
          this.current++;
        }

      },
      isActive(index) {
        return index === this.current;
      },
      setSelected() {
        if (this.open == false) {
          this.open = true;
          this.current = 0;
          this.id = '';
        }

        if (this.name.length > 1 || this.dataId) {
          let url = this.dataUrl;

          var useDataId = false;
          if (this.dataId && this.dataId.length > 0) {
            url += '?selectedId=' + this.dataId;
            useDataId = true;
          }
          else
            url += '?keyword=' + this.name;

          layoutService.autoCompleteSetSelected(url)
            .then(response => {
              this.suggestions = response;

              let selectedObject = useDataId ? this.suggestions[0] : null;
              if (selectedObject && selectedObject != null) {
                this.suggestionClick(this.suggestions.indexOf(selectedObject));
              }
            });
        }
      },
      change() {
        if (this.open == false) {
          this.open = true;
          this.current = 0;
          this.id = '';
        }

        if (this.name.length > 1) {
          let url = this.dataUrl;

          layoutService.getAutoCompleteItems(url, {
            params: {
              keyword: this.name
            }
          }).then(response => {
            this.suggestions = response;
          });
        }
      },
      suggestionClick(index) {
        this.name = this.matches[index].name;
        this.id = this.matches[index].id;
        this.open = false;

      },
      cardNoTitleText(displayNumber) {
        return displayNumber != null ? `# ${displayNumber}` : '';
      },
    },
    watch: {
      id: function (val) {
        this.id = val;
        document.getElementById(`${this.dataHiddenName}`).value = this.id;
        this.$bus.$emit(`${this.dataHiddenName}_onSelected`, this.id);
        if (this.dataHiddenName == 'LayoutCustomerID') {
          this.id = '';
          this.name = '';
        }
      },
      dataId: function (val) {
        if (val) {
          this.open = true;
          this.setSelected();
        }
      }
    },
    computed: {
      placeholderText() {
        const searchText = this.$t('Search');
        const branchName = this.$store.getters['Auth/currentBranchName'];

        if (branchName?.length == 0)
          return searchText;
        else
          return `${searchText} (${branchName})`;
      },
      matches() {
        return this.suggestions;
      },
      openSuggestion() {
        return this.name !== '' &&
          this.matches.length != 0 &&
          this.open === true;
      }
    },
    mounted: function () {
      if (this.dataId) {
        this.open = true;
        this.setSelected();
      }
    }
  };
</script>
