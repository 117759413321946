import axios from '../services/index'
import store from '../store/index'
import authService from '../services/auth'

let axiosInterceptor = null;

function interceptorsSetup() {

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  axiosInterceptor = axios.interceptors.response.use(response => {
    if (response.headers["x-server-version"] && response.headers["x-server-version"] != store.getters['Auth/clientVersion']) {
      store.dispatch('Auth/setIsClientUsingOldVersion', true)
    }
    return response;
  },
    error => {
      const originalRequest = error.config;
      if (error.response && error.response.data == "PermissionNotGranted") {
        window.vm.$toastr.error(window.vm.$t("PermissionNotGranted"));
      }
      else if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = token;
              return axios(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;


        return new Promise((resolve, reject) => {
          axios({
            url: "/api/auth/refreshToken", data: {
              token: store.getters['Auth/token'],
            }, method: "POST"
          })
            .then(response => {
              if (response.data && response.data.token) {
                const token = response.data.token;
                store.dispatch('Auth/setToken', token);

                let tokenWithBearer = store.getters['Auth/token'];

                axios.defaults.headers.common["Authorization"] = tokenWithBearer;
                error.config.headers['Authorization'] = tokenWithBearer;

                processQueue(null, tokenWithBearer);
                resolve(axios(originalRequest));

                store.dispatch('Auth/setIsTokenRefreshing', false)
              }
              else {
                failedQueue = [];
                store.dispatch('Auth/setIsTokenRefreshing', false)
                authService.logout();
                window.vm.$router.push({ name: 'auth.sign-in' })
                reject()
              }
            }).catch(err => {
              failedQueue = [];
              store.dispatch('Auth/setIsTokenRefreshing', false)
              authService.logout();
              window.vm.$router.push({ name: 'auth.sign-in' })
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
              store.dispatch('Auth/setIsTokenRefreshing', false)
            });
        })
      }

      return Promise.reject(error);

    })

  console.log(axiosInterceptor)
}

export { interceptorsSetup }
