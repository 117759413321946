<template>
  <component :is="$router.currentRoute.meta.layout">
    <router-view :key="$router.currentRoute.path" />
  </component>
</template>
<script>
  import { xray } from './config/pluginInit'

  export default {
    name: 'App',
    mounted() {
      xray.mainIndex()
    }
  }
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
