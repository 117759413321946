<template>
  <div>
    <header class="header-bar">
      <div class="logo">
        <img :src="logo" class="img-fluid" height:80px alt="logo">
      </div>
    </header>
    <section>
        <div>
          <div class="sign-in-from" style="position:relative !important">
            <router-view></router-view>
          </div>
        </div>
    </section>
    <br />
    <FooterStyle>
      <template v-slot:left>
      </template>
      <template v-slot:right>
        <span class="text-dark">Ⓒ 2024 Softage Yazılım A.Ş.</span>&nbsp;
        <!--<span class="text-dark">v{{ $store.getters['Auth/clientVersion'] }}</span>-->
      </template>
    </FooterStyle>
  </div>
</template>

<script>
  import logo from '../assets/images/logo-full-lg.png'
  import { xray } from '../config/pluginInit'
  import FooterStyle from '../components/xray/footer/FooterStyle'

  export default {
    name: 'MeetingLayout',
    components: {
      FooterStyle,
    },
    mounted() {
      xray.index()
    },
    data() {
      return {
        logo: logo
      }
    }
  }
</script>

<style scoped>
  .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(to right, rgb(8, 155, 171) 0%, rgb(13, 181, 200) 100%);
  }

    .header-bar::after {
      content: '';
      display: block;
      height: 15px; /* Alt kısmın yuvarlaklığı */
      background: linear-gradient(to right, rgb(8, 155, 171) 0%, rgb(13, 181, 200) 100%);
    }

  .logo img {
    height: 50px;
  }

  .footer {
    font-size: 14px;
  }
</style>
