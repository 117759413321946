<template>
  <div class="iq-sidebar" @mouseover="showFullLogo" @mouseleave="showIconLogo">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
        <img :src="logo" class="img-fluid" alt="logo" v-bind:style="isIconShow ? 'height:45px;' : 'height:80px;'">
      </router-link>
      <div class="iq-menu-bt-sidebar align-self-center" v-if="toggleButton">
        <div class="iq-menu-bt">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><font-awesome-icon icon="fa-regular fa-ellipsis" size="lg" /></div>
            <div class="hover-circle"><font-awesome-icon icon="fa-regular fa-ellipsis-vertical" size="lg" /></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <List :items="items" :open="true" />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
  import List from '../menus/ListStyle'
  import layoutService from '../../../services/layout'
  export default {
    name: 'SideBarStyle',
    props: {
      homeURL: { type: Object, default: () => ({ name: 'home.dashboard' }) },
      logoFull: { type: String, default: require('../../../assets/images/logo-full.png') },
      logoIcon: { type: String, default: require('../../../assets/images/logo-icon.png') },
      toggleButton: { type: Boolean, default: true },
      mini: { type: Boolean, default: false }
    },
    components: {
      List
    },
    data() {
      return {
        items: [],
        logo: '',
        isIconShow: false
      }
    },
    methods: {
      miniSidebar() {
        this.$emit('toggle')
      },
      showFullLogo() {
        if (this.mini) {
          this.logo = this.logoFull;
          this.isIconShow = false;
        }
      },
      showIconLogo() {
        if (this.mini) {
          this.logo = this.logoIcon;
          this.isIconShow = true;
        }
      },
      updateSideBarMenuHtmlElement() {
        const element = document.querySelectorAll('.iq-sidebar-menu li a')
        Array.from(element, (elem) => {
          elem.addEventListener('click', () => {
            const prevMenu = window.$('.iq-sidebar-menu li').has('.iq-submenu').find('ul.show').attr('id')
            const prevMenuElem = document.getElementById(prevMenu)
            if (prevMenuElem !== null) {
              Array.from(prevMenuElem.closest('li').getElementsByClassName('not-collapsed'), () => {
                if (elem.closest('ul').getAttribute('id') !== prevMenu) {
                  this.$root.$emit('bv::toggle::collapse', prevMenu)
                  prevMenuElem.closest('li').classList.remove('active')
                }
                if (elem.getAttribute('aria-controls') === prevMenu) {
                  this.$root.$emit('bv::toggle::collapse', prevMenu)
                }
              })
            }
            window.$(elem).closest('li').has('.iq-submenu').addClass('active')
          })
        })
      },
      getSideBarMenuItems() {
        layoutService.getSideBarMenuItems()
          .then((reponse) => {
            this.items = reponse;
          })
          .finally(() => {
            this.updateSideBarMenuHtmlElement();
          })
      }
    },
    mounted() {
      this.logo = this.mini ? this.logoIcon : this.logoFull;
      this.isIconShow = this.mini ? true : false;
      this.getSideBarMenuItems();
    }
  }
</script>
